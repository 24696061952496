<template>
  <v-dialog width="600" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn @click="open" icon v-on="on"
        ><v-icon size="40">mdi-plus-circle</v-icon></v-btn
      >
    </template>
    <v-card id="vacationCard">
      <v-card-title>{{ $t("vacations.choose_days") }} </v-card-title>
      <v-card-text>
        <form
          action
          data-vv-scope="vacation-form"
          @submit.prevent="validateForm('vacation-form')"
        >
          <v-row>
            <v-col cols="12" sm="6" class="py-0" align="center">
              <v-date-picker
                v-model="vacation.date"
                first-day-of-week="1"
                :min="
                  vacation.date == null || vacation.date.length == 2
                    ? ''
                    : vacation.date[0]
                "
                v-validate="'required'"
                color="primary"
                data-vv-name="date"
                :error-messages="errors.collect('vacation-form.date')"
                range
              ></v-date-picker>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-row>
                <v-col class="py-0" cols="12">
                  <v-row>
                    <v-col class="py-0" cols="12">
                      <label> {{ $t("vacations.date") }}</label>
                      <v-text-field
                        readonly
                        outlined
                        hide-details
                        dense
                        v-model="dateRangeText"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12">
                      <label> {{ $t("vacations.reason") }}</label>
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        v-model="vacation.reason"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  align="right"
                  style="
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                  "
                >
                  <v-btn
                    outlined
                    @click="dialog = false"
                    style="width: 100px !important; height: 30px"
                    class="mr-3"
                    elevation="0"
                    >{{ $t("cancel") }}</v-btn
                  >
                  <v-btn
                    style="
                      width: 100px !important;
                      color: #363533;
                      height: 30px;
                      padding-top: 10px !important;
                    "
                    elevation="0"
                    type="submit"
                  >
                    <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                    {{ $t("save", { name: $t("") }) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "FormCloseDays",

  computed: {
    dateRangeText() {
      if (this.vacation.date.length > 0) {
        let data = [this.$d(new Date(this.vacation.date[0]), "dateShort")];
        if (this.vacation.date.length == 2) {
          data[1] = this.$d(new Date(this.vacation.date[1]), "dateShort");
        }
        return data.join(" ~ ");
      }
    },
  },
  data() {
    return {
      dialog: false,
      action: "save",
      id: null,
      dates: [],
      vacation: {
        reason: null,
        date: [],
        studio_id: this.$store.state.auth.user.studio.id,
      },
    };
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    async validateForm(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        if (result && this.action === "save") {
          this.saveVacations();
        } else if (result && this.action === "edit") {
          this.updateVacations();
        }
      });
    },
    ...mapActions("close_days", [
      "addVacation",
      "getVacation",
      "updateVacation",
      "getAll",
    ]),

    saveVacations() {
      this.loading = true;
      console.log(this.vacation);
      if (this.vacation.date.length < 2) {
        this.vacation.date[1] = this.vacation.date[0];
      }
      this.addVacation({
        vacation: this.vacation,
      }).then((data) => {
        if (data.success) {
          this.dialog = false;
          this.$emit("save");
        } else if (data.error) {
          this.$alert(this.$t("vacations.error"), "", "");
        }
      });
    },
    updateVacations() {
      this.loading = true;
      console.log(this.vacation);
      if (this.vacation.date.length < 2) {
        this.vacation.date[1] = this.vacation.date[0];
      }
      this.updateVacation({
        vacation: this.vacation,
        id_vacation: this.id,
      }).then((data) => {
        if (data.success) {
          this.dialog = false;
          this.$emit("save");
        } else if (data.error) {
          this.$alert(this.$t("vacations.error"), "", "");
        }
      });
    },
    fetchAll() {
      this.getAll().then((data) => {
        if (data) {
          console.log(data[0].start_date);
          this.dates = data;
        }
      });
    },
    open() {
      (this.id = null),
        (this.vacation = {
          reason: null,
          date: [],
          studio_id: this.$store.state.auth.user.studio.id,
        });
      this.action = "save";
      this.dialog = true;
    },
    openEdit() {
      this.loading = true;
      this.getVacation(this.id).then((data) => {
        if (data) {
          console.log(data);
          this.vacation.reason = data.reason;

          this.vacation.date = [data.start_date];
          if (data.end_date) {
            this.vacation.date[1] = data.end_date;
          }
          console.log(this.vacation);
          this.action = "edit";
          this.dialog = true;
        }
      });
    },
  },
};
</script>
<style lang="sass">
#vacationCard
  .v-picker__title
    display: none
  label
    display: block
    height: 25px !important
</style>
